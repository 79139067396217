.header {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  border-top: $secondary-color 5px solid;
  padding: 30px;
  position: sticky;
  top: 0;
  background-color: $color-light;
  z-index: 100;
  @media (max-width: 1035px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo-wrap {
    display: flex;
    width: 100px;
    justify-self: center;
    .logo {
      width: 100%;
    }
  }
  .navbar {
    align-self: center;
    justify-self: center;
    .navlink {
      font-size: 1.2rem;
      font-weight: 300;
      padding: 25px 25px;
      &:hover {
        border-bottom: $secondary-color 5px solid;
      }
    }
    @media (max-width: 1035px) {
      display: none;
    }
  }
  .right {
    justify-self: center;
    align-self: center;
    display: flex;
    .info {
      padding-right: 20px;
      border-right: 1px solid $color-dark;
      font-size: 1.2rem;
      .phone-icon {
        // rotate 180deg
        transform: rotate(90deg);
        color: $primary-color;
      }
    }
    .sidebar-toggler {
      padding-left: 20px;
      font-size: 1.2rem;
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

.side-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: $color-light;
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  box-shadow: 1px 1px 1px gray;
  transition: all 0.2s ease;
  transform: translate(100%);
  z-index: 1;
  .toggler-btn {
    padding: 15px;
  }
  .side-bar-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .side-bar-header {
      margin: 25px auto;
    }
    .side-bar-links {
      .side-bar-link {
        padding: 15px;
        font-size: 1.2rem;
        font-weight: 300;
        cursor: pointer;
        &:hover {
          border-bottom: $secondary-color 1px solid;
        }
      }
    }
    .ant-dropdown-trigger {
      margin: 15px 0;
    }
  }
}

.show {
  transform: translate(0);
}

.footer {
  background-color: #042433;
  color: $color-light;
  a {
    color: $color-light;
    &:hover {
      color: $secondary-color;
    }
  }
  .footer-content {
    width: 85%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 35px 0;
    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }
    .footer {
      &-info {
        padding: 0 20px;
        @media (max-width: 410px) {
          padding: 0 10px;
        }
        .logo-wrap {
          width: 150px;
          margin-bottom: 15px;
          @media (max-width: 410px) {
            width: 100px;
          }
          .logo {
            width: 100%;
          }
        }
      }
      &-industries,
      &-menu {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        @media (max-width: 410px) {
          padding: 0 10px;
        }
        .heading {
          font-weight: 700;
          letter-spacing: 1px;
          font-size: 1.4rem;
          padding: 15px 0;
          @media (max-width: 420px) {
            font-size: 1.1rem;
          }
        }
        a {
          padding: 5px 0;
          font-size: 14px;
        }
      }
      &-contact {
        padding: 0 20px;
        @media (max-width: 410px) {
          padding: 0 10px;
        }
        .heading {
          font-weight: 700;
          letter-spacing: 1px;
          font-size: 1.4rem;
          padding: 15px 0;
          @media (max-width: 420px) {
            font-size: 1.1rem;
          }
        }
        .item {
          padding: 5px 0;
          @media (max-width: 420px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .copyright {
    padding: 20px;
    text-align: center;
    border-top: $primary-color 1px solid;
  }
}
