* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $primary-font;
}

a {
  color: $color-dark;
  &:hover {
    color: $secondary-color;
  }
}

.container {
  width: 85%;
  margin: auto;
  @media (max-width: 800px) {
    width: 90%;
  }
}

.component {
  margin: 50px 0;
}

ul {
  list-style-type: none;
}

.page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
    url("../../assets/images/about-banner.png") center center / cover no-repeat;
  height: calc(100vh - 131.66px);
  display: flex;
  justify-content: center;
  align-items: center;
  .text {
    text-align: center;
    h1 {
      color: $color-light;
      font-size: 2.5rem;
    }
    p {
      color: $color-light;
      font-size: 1.6rem;
      width: 600px;

      @media (max-width: 660px) {
        margin: 0 40px;
        width: fit-content;
        font-size: 1.4rem;
      }
    }
  }
}

.side-by-side {
  .container {
    display: flex;
    align-items: center;
    .left {
      padding: 50px;
      width: 50%;
      img {
        width: 100%;
      }
    }
    .right {
      width: 50%;
      padding: 0 50px;
      .title {
        span {
          text-transform: uppercase;
          font-weight: 300;
          font-size: 1.2rem;
        }
        .main {
          font-weight: 700;
          font-size: 2.5rem;
          line-height: 1.4;
        }
      }
      p {
        padding: 25px 0;
        a {
          font-weight: 600;
        }
      }
    }
  }
}
