.contact-page {
  .page-header {
    background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
      url("../../assets/images/about-banner.png") center center / cover
        no-repeat;
    height: calc(100vh - 131.66px);
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      text-align: center;
      h1 {
        color: $color-light;
        font-size: 2.5rem;
      }
      p {
        color: $color-light;
        font-size: 1.6rem;
        width: 600px;

        @media (max-width: 660px) {
          margin: 0 40px;
          width: fit-content;
          font-size: 1.4rem;
        }
      }
    }
  }
  .container {
    .content {
      padding: 50px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 40%;
      }
      @media (max-width: 1030px) {
        flex-direction: column-reverse;
        > div {
          width: 70%;
        }
      }
      form {
        width: 60%;
        margin: auto;
        padding-left: 100px;
        @media (max-width: 1030px) {
          padding-left: 0;
          width: 80%;
        }
        .heading {
          font-size: 2rem;
          padding: 25px 0;
          font-weight: 700;
          @media (max-width: 1030px) {
            text-align: center;
          }
        }
      }
      .info {
        text-align: left;
        padding: 25px 0;
        font-size: 1.3rem;
        margin-left: 40px;
        .heading {
          font-size: 1.5rem;
          padding: 10px 0;
          font-weight: 600;
        }
      }
    }
  }
  .map-wrap {
    iframe {
      height: 450px; // default height for desktop

      @media (max-width: 768px) { // 768px is a common breakpoint for mobile devices
        height: 50vh; // 50% of the viewport height
      }
    }
  }
}
