.industries-page {
  .page-header {
    .text {
      p {
        font-size: 1.1rem;
      }
    }
  }
  .industries {
    background: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%),
      url("../../assets/images/industries-bg.jpg") top center / cover no-repeat;
    color: $color-light;
    height: 400px;
    .title {
      text-align: center;
      padding: 50px 0;
      span {
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 600;
      }
      .main {
        font-size: 1.8rem;
        font-weight: 700;
      }
    }
  }
  .industry-cards {
    margin-top: -200px;
    margin-bottom: 50px;
    .container {
      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        @media (max-width: 1000px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 550px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .industry-card {
          .content {
            width: 100%;
            position: relative;
            // width: 90%;
            max-width: 400px;
            margin: auto;
            overflow: hidden;
            .content-overlay {
              background: rgba(0, 0, 0, 0.7);
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              opacity: 0;
              -webkit-transition: all 0.4s ease-in-out 0s;
              -moz-transition: all 0.4s ease-in-out 0s;
              transition: all 0.4s ease-in-out 0s;
            }
            .img-wrap {
              height: 250px;
              background-color: $color-light;
              display: flex;
              align-items: center;
            }
            img {
              width: 90%;
              margin: auto;
            }
            .content-details {
              position: absolute;
              text-align: center;
              padding-left: 1em;
              padding-right: 1em;
              width: 100%;
              top: 50%;
              left: 50%;
              opacity: 0;
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              -webkit-transition: all 0.3s ease-in-out 0s;
              -moz-transition: all 0.3s ease-in-out 0s;
              transition: all 0.3s ease-in-out 0s;
              color: $color-light;
              h3 {
                color: $color-light;
                font-size: 1.3rem;
                @media (max-width: 650px) {
                  font-size: 1.1rem;
                }
              }
              @media (max-width: 650px) {
                p {
                  display: none;
                }
              }
            }
            .fadeIn-left {
              left: 20%;
            }
            &:hover {
              .content-overlay {
                opacity: 1;
              }
              .content-details {
                top: 50%;
                left: 50%;
                opacity: 1;
              }
            }
          }
          .text {
            text-align: center;
            background-color: rgb(231, 231, 231);
            padding: 10px 0;
            font-size: 1.2rem;
            font-weight: 700;
          }
        }
      }
    }
  }
  .side-by-side {
    .container {
      flex-direction: row-reverse;
    }
  }
}

.industry-page {
  .industry-header {
    display: flex;
    align-items: center;
    @media (max-width: 560px) {
      flex-direction: column-reverse;
      .right {
        padding-bottom: 25px;
      }
    }
    .left {
      padding-right: 40px;
      @media (max-width: 560px) {
        padding-right: 0;
      }
      .heading {
        font-size: 4rem;
        text-transform: uppercase;
        font-weight: 700;
        @media (max-width: 1000px) {
          font-size: 2rem;
        }
        @media (max-width: 560px) {
          text-align: center;
        }
      }
      .caption {
        font-size: 1.5rem;
        line-height: 1.4;
        @media (max-width: 1000px) {
          font-size: 1.2rem;
        }
        @media (max-width: 768px) {
          font-size: 1rem;
        }
        @media (max-width: 560px) {
          text-align: center;
        }
      }
    }
    .right {
      .logo-wrap {
        width: 300px;
        @media (max-width: 860px) {
          width: 200px;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .main-img-wrap {
    width: 100%;
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .industry-about {
    text-align: center;
    .title {
      font-weight: 700;
      font-size: 2rem;
      padding-bottom: 20px;
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
    .description {
      font-size: 1.2rem;
      line-height: 1.4;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
    .btn-wrap {
      margin-top: 30px;
      .btn {
        font-size: 1.2rem;
        font-weight: 700;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        background-color: $primary-color;
        color: $color-light;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
        &:hover {
          background-color: $secondary-color;
        }
      }
    }
  }
  .specialities {
    .title {
      font-size: 2rem;
      padding-bottom: 20px;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
    .ant-card {
      color: $color-light;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
      .ant-card-head {
        background-color: $secondary-color;
        color: $color-light;
      }
      .ant-card-body {
        background-color: $secondary-color;
        min-height: 172px;
        border-top: 0.5px solid #ccc;
      }
    }
  }
}
