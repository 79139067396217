.about-page {
  .page-header {
    background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
      url("../../assets/images/about-banner.png") center center / cover no-repeat;
    height: calc(100vh - 131.66px);
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      text-align: center;

      h1 {
        color: $color-light;
        font-size: 2.5rem;
      }

      p {
        color: $color-light;
        font-size: 1.6rem;
        width: 600px;

        @media (max-width: 660px) {
          margin: 0 40px;
          width: fit-content;
          font-size: 1.4rem;
        }
      }
    }
  }

  .how-we-started {
    margin-top: 50px;

    .container {
      display: flex;
      align-items: center;

      @media (max-width: 630px) {
        flex-direction: column;
      }
    }

    .left,
    .right {
      width: 50%;

      @media (max-width: 630px) {
        width: 100%;
      }
    }

    .right {
      img {
        display: flex;
        margin: auto;
        width: 100%;
      }

      @media (max-width: 630px) {
        padding-top: 20px;
      }
    }

    .left {
      padding: 0px;

      .title {
        padding-bottom: 20px;

        .mini {
          font-weight: 300;
          font-size: 1.1rem;
        }

        .main {
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
    }
  }

  // .side-by-side {
  //   margin-top: 50px;
  //   .container {
  //     display: flex;
  //     align-items: center;
  //     @media (max-width: 630px) {
  //       flex-direction: column;
  //     }
  //   }
  //   .left,
  //   .right {
  //     width: 50%;
  //     @media (max-width: 630px) {
  //       width: 80%;
  //     }
  //   }
  //   .left {
  //     img {
  //       width: 100%;
  //     }
  //     @media (max-width: 630px) {
  //       padding-top: 20px;
  //     }
  //   }
  //   .right {
  //     padding: 0 20px;
  //     .title {
  //       padding-bottom: 20px;
  //       .mini {
  //         font-weight: 300;
  //         font-size: 1.1rem;
  //       }
  //       .main {
  //         font-size: 1.8rem;
  //         font-weight: 600;
  //       }
  //     }
  //   }
  // }
  .side-by-side {
    .container {
      display: flex;
      align-items: center;

      @media (max-width: 630px) {
        flex-direction: column-reverse;
      }

      .left,
      .right {
        width: 50%;

        @media (max-width: 630px) {
          width: 100%;
        }
      }

      .left {
        img {
          width: 100%;
        }

        @media (max-width: 630px) {
          padding-top: 0px;
        }
      }

      .right {
        padding: 0px;

        .title {
          padding-bottom: 20px;

          .mini {
            font-weight: 300;
            font-size: 1.1rem;
          }

          .main {
            font-size: 2rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  .info {
    padding: 50px 0;
    margin-bottom: 0;
    background-color: $primary-color;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .text {
        text-align: center;
        font-size: 2rem;
        color: $color-light;
      }

      button {
        background-color: $color-light;
        color: $color-dark;
        // padding: 15px 20px;
        border-radius: 5px;
        margin: auto;
        border: none;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}