  .home-page {
  .page-header {
    width: 100%;
    height: 100%;
    height: calc(100vh -   131.66px);
    @media (max-width: 768px) { 
      height: 60%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
     
    }
  }
  .about {
    .container {
      display: flex;
      align-items: center;
      @media (max-width: 1020px) {
        flex-direction: column-reverse;
      }
      .left {
        padding: 50px;
        @media (max-width: 610px) {
          img {
            width: 300px;
          }
        }
        @media (max-width: 420px) {
          img {
            width: 250px;
          }
        }
      }
      .right {
        padding: 0 50px;
        .title {
          span {
            text-transform: uppercase;
            font-weight: 300;
            font-size: 1.2rem;
          }
          .main {
            font-weight: 700;
            font-size: 2.5rem;
            line-height: 1.4;
            @media (max-width: 1100px) {
              font-size: 2rem;
            }
          }
        }
        p {
          padding: 25px 0;
        }
      }
    }
  }
  .services {
    .container {
      ul {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        @media (max-width: 935px) {
          flex-wrap: wrap;
          > li {
            width: 40%;
            // margin-bottom: 30px;
          }
        }
        @media (max-width: 450px) {
          > li {
            width: 80%;
          }
        }
        li {
          display: flex;
          align-items: center;
          padding: 0 40px;
          @media (max-width: 1000px) {
            flex-direction: column;
          }
          @media (max-width: 525px) {
            padding: 0 20px;
          }
          .icon {
            padding: 20px;
            width: 150px;
            img {
              width: 100%;
            }
          }
          .data {
            @media (max-width: 1000px) {
              text-align: center;
            }
            .heading {
              font-weight: 700;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
  .stats {
    background: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%),
      url("../../assets/images/stats-bg.jpg") center center / cover no-repeat;
    color: $color-light;
    margin-bottom: 0;
    .container {
      display: flex;
      justify-content: space-evenly;
      padding: 50px 0;
      @media (max-width: 750px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .stat {
          margin-bottom: 15px;
        }
      }
      .text {
        font-size: 1.8rem;
      }
      .stat {
        text-align: center;
        .name {
          font-size: 1.3rem;
          @media (max-width: 420px) {
            font-size: 1.1rem;
          }
        }
        .value {
          font-weight: 700;
          font-size: 1.9rem;
          @media (max-width: 420px) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .get-started {
    .container {
      width: 80%;
      @media (max-width: 1200px) {
        width: 80%;
      }
      @media (max-width: 800px) {
        width: 90%;
      }
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .text {
          padding: 25px 50px;
          .heading {
            font-weight: 700;
            font-size: 1.5rem;
            padding: 20px 0;
            @media (max-width: 450px) {
              font-size: 1.3rem;
            }
          }
          @media (max-width: 450px) {
            padding: 25px;
          }
          @media (max-width: 630px) {
            .description {
              display: none;
            }
          }
        }
        .button-wrap {
          padding: 25px 25px;
          .contact-btn {
            padding: 10px 20px;
            font-size: 1.2rem;
            font-weight: 700;
            width: fit-content;
            border-radius: 5px;
            border: 1px solid $color-dark;
            background: transparent;
            color: $color-dark;
            cursor: pointer;
            @media (max-width: 450px) {
              font-size: 1rem;
            }
            &:hover {
              transition: all 0.5s ease-in-out;
              background: $color-dark;
              color: $color-light;
            }
          }
        }
      }
    }
  }
}
