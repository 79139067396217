.career-page {
  .title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    .heading {
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
      padding-top: 50px;
    }
    span {
      text-align: center;
    }
  }
  .openings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    padding-bottom: 50px;
    .opening {
      border-bottom: 1px solid #ccc;
      &:first-child {
        border-top: 1px solid #ccc;
      }
      .wrapper {
        width: 95%;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 4fr 2fr;
        align-items: center;
        padding: 20px 0;
        @media (max-width: 450px) {
          grid-template-columns: 1fr 4fr 1fr;
        }
        .image {
          width: 70%;
          margin: auto;
          img {
            width: 100%;
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 80%;
          padding: 0 25px;
          .title {
            font-size: 1.2rem;
            font-weight: 600;
            align-items: flex-start;
            
          }
          .details-bot {
            display: flex;
            .address {
              padding-right: 15px;
            }
            .category {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .apply-wrap {
    display: flex;
    justify-content: flex-end;
    button {
      padding: 10px 20px;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 700;
      color: #ffffff;
      background-color: $primary-color;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      @media (max-width: 450px) {
        font-size: 1rem;
        padding: 5px 10px;
      }
      &:hover {
        background-color: $secondary-color;
        transition: all 1s ease;
      }
    }
  }
}

.career-detail-page {
  .career-header {
    background-color: $secondary-color;
    color: $color-light;
    text-align: center;
    padding: 30px 0;
    .title {
      font-size: 2.5rem;
      font-weight: 700;
      padding-bottom: 30px;
    }
    .caption {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      > div {
        padding: 0 15px;
      }
      span {
        padding-right: 10px;
      }
    }
  }
  .container {
    width: 70%;
    padding-bottom: "40px";
    .component {
      .heading {
        font-size: 1.3rem;
        font-weight: 700;
        padding-bottom: 20px;
      }

      .description {
        font-size: 1.1rem;
        @media (max-width: 450px) {
          font-size: 1rem;
        }
      }

      .list {
        list-style-type: disc;
        margin-left: 20px;

        li {
          padding: 5px 0;
          font-size: 1.1rem;
        }
      }
      .file-name {
        padding-left: 1rem;
        color: $primary-color;
      }
    }
  }
}
